




































.objectid {
  border: 1px solid var(--v-primary-base);
  padding: 0.1rem 0.2rem;
  cursor: pointer;
  font-family: monospace;
}
