



































































.addCardBorderColor {
  border: 5px solid var(--v-borderColor-base);
  border-radius: 20px;
}
