






















































































































































































































@import '~vuetify/src/styles/settings/_variables.scss';

.va-sidebar {
  position: relative;
  top: initial;
  left: initial;
  height: auto;
  min-height: initial;

  ::v-deep &__menu {
    padding: 0;
  }

  ::v-deep .va-sidebar-link {
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  }
}

@media #{map-get($display-breakpoints, 'sm-and-down')} {
  .list.selected {
    display: none;
  }
}
@media #{map-get($display-breakpoints, 'md-and-up')} {
  .goBack {
    display: none;
  }
}

.addParent {
  display: flex;
  justify-content: flex-end;
}
.text--secondary {
  background-color: var(--v-drawerHover-base);
  border-color: var(--v-drawerHover-base);
  padding: 10px;
  box-shadow: 3px 3px 3px grey;
  animation: bounce 2s linear 1;
}
@keyframes bounce {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.2);
  }
  100% {
    transform: scale(1);
  }
}
.text--secondary:active {
  box-shadow: 1px 1px 1px grey;
  transform: translateY(2px);
}
.list {
  background-color: transparent;
}
